import axios, { AxiosResponse } from 'axios';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import {
  AdminUserViewDTO,
  configStatusDTO,
  CustomerDTO,
  CustomersDTO,
  FlagDataDTO,
  FlagDto,
  HubDTO,
  HubsDTO,
  HubsPermissionsDTO,
  HubTypesWithFlag,
  InviteByEmailDTO,
  LoginDto,
  NotificationDTO,
  TaskDTO,
  TwoFactorDto,
  UserDTO,
  UserInvitesDto,
  UserPermissionsDataDTO,
  UserPermissionsDTO,
} from './dto';

const isDevelopment = process.env.REACT_APP_DEVELOPMENT === 'true';

const getBaseUrlDev = () => {
  return 'http://localhost:8000/';
};

const getBaseUrlProd = () => {
  return 'https://gw-backend.iqu.no/';
};

export function baseUrl() {
  if (isDevelopment) {
    return getBaseUrlDev();
  } else {
    return getBaseUrlProd();
  }
}

const AuthHeader = () => {
  return { Authorization: 'Bearer ' + Cookies.get('accessToken') };
};

const cache = new Map();

function getCacheKey(url: string, params?: unknown) {
  return url + JSON.stringify(params || {});
}

async function fetchWithCache<T>(
  key: string,
  fetchFn: () => Promise<T>,
  cacheTime = 300,
  forceRefresh = false,
): Promise<T> {
  if (!forceRefresh && cache.has(key)) {
    const { data, expiry } = cache.get(key);
    if (Date.now() < expiry) {
      return data;
    } else {
      cache.delete(key);
    }
  }
  const data = await fetchFn();
  cache.set(key, { data, expiry: Date.now() + cacheTime * 1000 });
  return data;
}

// ========================
// GET Requests
// ========================

export async function IsTwoFactorAuthAPI(): Promise<boolean> {
  const url = baseUrl() + 'api/two-factor-auth/';
  const key = getCacheKey(url);
  try {
    return await fetchWithCache(key, async () => {
      const response = await axios.get(url, { headers: AuthHeader() });
      return response.status === 200;
    });
  } catch (error) {
    return false;
  }
}

export async function AllHubs(customerId?: string): Promise<HubsDTO> {
  const params = customerId ? { customer_id: customerId } : {};
  const url = baseUrl() + 'api/hub/';
  const key = getCacheKey(url, params);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader(), params });
    return response.data;
  });
}

export async function GetCustomers(): Promise<CustomersDTO> {
  const url = baseUrl() + 'api/customer/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetCustomer(customer_id: string): Promise<CustomerDTO> {
  const url = baseUrl() + `api/customer/${customer_id}`;
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetHubPermission(model_id: string): Promise<UserPermissionsDataDTO> {
  const url = baseUrl() + `api/user-permissions/${model_id}/`;
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function HubAPI(hubId: string): Promise<HubDTO> {
  const url = baseUrl() + 'api/hub/' + hubId;
  const key = getCacheKey(url);
  return fetchWithCache(
    key,
    async () => {
      const response = await axios.get(url, { headers: AuthHeader() });
      return response.data;
    },
    60,
  );
}

export async function UserAPI(): Promise<UserDTO> {
  const url = baseUrl() + 'api/me/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function AllUserAPI(): Promise<[AdminUserViewDTO]> {
  const url = baseUrl() + 'api/user/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetUserAPI(user_id: string): Promise<AdminUserViewDTO> {
  const url = baseUrl() + 'api/user/' + user_id;
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function AllFlags(queryString: string = ''): Promise<FlagDataDTO> {
  const url = baseUrl() + `api/flag/${queryString}`;
  const key = getCacheKey(url);
  return fetchWithCache(
    key,
    async () => {
      const response = await axios.get(url, { headers: AuthHeader() });
      return response.data;
    },
    0,
    true,
  );
}

export async function GetHubFlagsAPI(hub_id: string): Promise<FlagDto[]> {
  const url = baseUrl() + 'api/hub/' + hub_id + '/flag/';
  const key = getCacheKey(url);
  return fetchWithCache(
    key,
    async () => {
      const response = await axios.get(url, { headers: AuthHeader() });
      return response.data;
    },
    0,
    true,
  );
}

export async function GetFlagAPI(hub_id: string, flag_id: string): Promise<FlagDto> {
  const url = baseUrl() + 'api/hub/' + hub_id + '/flag/' + flag_id;
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetHubWithFlags(): Promise<HubTypesWithFlag> {
  const url = baseUrl() + 'api/hubs/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetConfigFileStatus(hub_id: string, filename: string): Promise<configStatusDTO[]> {
  const url = baseUrl() + 'api/commands/' + hub_id + '/config/status?filename=' + filename;
  const key = getCacheKey(url);
  return fetchWithCache(
    key,
    async () => {
      const response = await axios.get(url, { headers: AuthHeader() });
      return response.data;
    },
    0,
    true,
  );
}

export async function GetConfigFile(hub_id: string, file_path: string) {
  const url = baseUrl() + 'api/commands/' + hub_id + '/config?file_path=' + file_path;
  const key = getCacheKey(url);
  return fetchWithCache(
    key,
    async () => {
      const response = await axios.get(url, { headers: AuthHeader(), responseType: 'blob' });
      return response;
    },
    0,
    true,
  );
}

export async function GetUserInvites(): Promise<UserInvitesDto[]> {
  const url = baseUrl() + 'api/user-invite/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetCreateUser(inviteId: string): Promise<InviteByEmailDTO> {
  const url = baseUrl() + 'api/invite/' + inviteId + '/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url);
    return response.data;
  });
}

export async function GetHubPermissions(hub_id: string): Promise<[HubsPermissionsDTO]> {
  const url = baseUrl() + 'api/hub/' + hub_id + '/permissions/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetProfilePicture(): Promise<string | null> {
  const url = baseUrl() + 'api/me/picture/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    try {
      const response = await axios.get(url, { headers: AuthHeader(), responseType: 'blob' });
      if (response.status === 404) {
        return null;
      }
      return URL.createObjectURL(response.data);
    } catch (error) {
      return null;
    }
  });
}

export async function GetProfileAdminPicture(user_id: string): Promise<string | null> {
  const url = baseUrl() + 'api/user/' + user_id + '/picture';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    try {
      const response = await axios.get(url, { headers: AuthHeader(), responseType: 'blob' });
      if (response.status === 404) {
        return null;
      }
      return URL.createObjectURL(response.data);
    } catch (error) {
      return null;
    }
  });
}

export async function GetUserAdminPermissions(user_id: string): Promise<[UserPermissionsDTO]> {
  const url = baseUrl() + 'api/user/' + user_id + '/permissions/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetNotifications(): Promise<NotificationDTO[]> {
  const url = baseUrl() + 'api/notifications/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetVersion() {
  const url = baseUrl() + 'api/version';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url);
    return response.data;
  });
}

export async function GetCommands(model: string): Promise<string[]> {
  const url = baseUrl() + 'api/commands-definitions/' + model + '/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetCommandsLog(id: string): Promise<TaskDTO[]> {
  const url = baseUrl() + 'api/commands/' + id + '/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetSchema(schema: string): Promise<
  | {
      properties: Record<string, unknown>;
      $defs: Record<string, unknown>;
    }
  | undefined
> {
  let url: string | undefined;
  switch (schema) {
    case 'customer':
      url = baseUrl() + 'api/customer-schema/';
      break;
    case 'hubpermission':
      url = baseUrl() + 'api/hub-permission-schema/';
      break;
    case 'core':
      url = baseUrl() + 'api/hub-schema/core/';
      break;
    case 'sic':
      url = baseUrl() + 'api/hub-schema/sic/';
      break;
  }
  if (url !== undefined) {
    const key = getCacheKey(url as string);
    return fetchWithCache(
      key,
      async () => {
        const response = await axios.get(url as string, { headers: AuthHeader() });
        return response.data;
      },
      300,
    );
  }
}

export async function GetAllHubPermissions(): Promise<[UserPermissionsDataDTO]> {
  const url = baseUrl() + 'api/user-permissions/';
  const key = getCacheKey(url);
  return fetchWithCache(key, async () => {
    const response = await axios.get(url, { headers: AuthHeader() });
    return response.data;
  });
}

export async function GetDockerImages(customer_id: string): Promise<Record<string, string>> {
  const url = baseUrl() + 'api/commands/' + customer_id + '/docker-images/';
  const key = getCacheKey(url);
  return fetchWithCache(
    key,
    async () => {
      const response = await axios.get(url, { headers: AuthHeader() });
      return response.data;
    },
    0,
    true,
  );
}

// ========================
// POST Requests
// ========================

export async function Login(userName: string, password: string): Promise<LoginDto> {
  const response = await axios.post<LoginDto>(baseUrl() + 'api/token/', {
    username: userName,
    password: password,
  });
  return response.data;
}

export async function TwoFactorAuthAPI(code: string): Promise<number> {
  const response = await axios.post<TwoFactorDto>(
    baseUrl() + 'api/two-factor-auth/',
    { code: code },
    { headers: AuthHeader() },
  );
  return response.status;
}

export async function VerifyToken(): Promise<boolean> {
  try {
    const response = await axios.post(baseUrl() + 'api/token/verify/', { token: Cookies.get('accessToken') });
    return response.status == 200;
  } catch (error) {
    return false;
  }
}

export async function SendHubCommand(
  hub_id: string,
  command: string,
  data?: unknown,
): Promise<AxiosResponse<unknown, unknown>> {
  return await axios.post(
    baseUrl() + 'api/commands/' + hub_id + '/',
    { command: command, data: data },
    { headers: AuthHeader() },
  );
}

export async function SendUserInvite(email: string): Promise<number> {
  const response = await axios.post(baseUrl() + 'api/user-invite/', { email: email }, { headers: AuthHeader() });
  return response.status;
}

export async function CreateUserAPI(
  inviteId: string,
  username: string,
  phone_number: string,
  password: string,
  first_name: string,
  last_name: string,
): Promise<number> {
  const response = await axios.post(baseUrl() + 'api/invite/' + inviteId + '/', {
    username: username,
    phone_number: phone_number,
    password: password,
    first_name: first_name,
    last_name: last_name,
  });
  return response.status;
}

export async function PostHubPermissions(
  hub_id: string,
  user_id: string,
  can_delete: boolean,
  update: boolean,
  view: boolean,
  add_users: boolean,
): Promise<number> {
  const data = {
    user_id: user_id,
    delete: can_delete,
    update: update,
    view: view,
    add_users: add_users,
  };
  const response = await axios.post(
    baseUrl() + 'api/hub/' + hub_id + '/permissions/',
    { data },
    { headers: AuthHeader() },
  );
  return response.status;
}

export async function PostCustomer() {
  const response = await axios.post(
    baseUrl() + 'api/customer/',
    { company_name: t('new_customer') },
    { headers: AuthHeader() },
  );
  return response.data;
}

export async function PostHub(hub_type: string) {
  const response = await axios.post(
    baseUrl() + 'api/create-hub/' + hub_type + '/',
    { hub_name: t(`new_${hub_type}`) },
    { headers: AuthHeader() },
  );
  return response.data;
}

export async function PostHubPermission() {
  const response = await axios.post(baseUrl() + 'api/user-permissions/', {}, { headers: AuthHeader() });
  return response.data;
}

export async function UploadProfilePicture(imageFile: File): Promise<string> {
  const formData = new FormData();
  formData.append('profile_picture', imageFile);
  const response = await axios.post(baseUrl() + 'api/me/picture/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + Cookies.get('accessToken'),
    },
    responseType: 'blob',
  });
  return URL.createObjectURL(response.data);
}

export async function PostDockerImages(customer_id: string, data: unknown) {
  const response = await axios.post(baseUrl() + 'api/commands/' + customer_id + '/docker-images/', data, {
    headers: AuthHeader(),
  });
  return response.data;
}

// ========================
// PUT Requests
// ========================

export async function DeactivateFlag(hub_id: string, flag_id: number): Promise<FlagDto[]> {
  const response = await axios.put(
    baseUrl() + 'api/hub/' + hub_id + '/flag/' + flag_id + '/',
    { active: false },
    { headers: AuthHeader() },
  );
  return response.data;
}

export async function UpdateHub(hub_id: string, data: unknown): Promise<number> {
  const response = await axios.put(baseUrl() + 'api/hub/' + hub_id + '/', { data }, { headers: AuthHeader() });
  return response.status;
}

export async function UpdateUserInvite(email: string, time: number): Promise<number> {
  const response = await axios.put(
    baseUrl() + 'api/user-invite/',
    { email: email, time: time },
    { headers: AuthHeader() },
  );
  return response.status;
}

export async function ViewedNotification(id: number, viewed: boolean): Promise<NotificationDTO> {
  const response = await axios.put(
    baseUrl() + 'api/notification/' + id + '/',
    { viewed: viewed },
    { headers: AuthHeader() },
  );
  return response.data;
}

export async function ViewedNotifications(notification_ids: number[], viewed: boolean): Promise<NotificationDTO> {
  const response = await axios.put(
    baseUrl() + 'api/notifications/',
    { notification_ids: notification_ids, viewed: viewed },
    { headers: AuthHeader() },
  );
  return response.data;
}

export async function PutCustomer(id: string, data: unknown) {
  const response = await axios.put(baseUrl() + 'api/customer/' + id + '/', data, { headers: AuthHeader() });
  return response.data;
}

export async function PutHubPermission(id: string, data: unknown) {
  const response = await axios.put(baseUrl() + 'api/user-permissions/' + id + '/', data, { headers: AuthHeader() });
  return response.data;
}

// ========================
// DELETE Requests
// ========================

export async function DeleteUserInvite(email: string): Promise<number> {
  const response = await axios.delete(baseUrl() + 'api/user-invite/', {
    headers: AuthHeader(),
    data: { email: email },
  });
  return response.status;
}

export async function DeleteCustomer(id: string) {
  const response = await axios.delete(baseUrl() + 'api/customer/' + id + '/', { headers: AuthHeader() });
  return response.data;
}

export async function DeleteHubPermission(id: string) {
  const response = await axios.delete(baseUrl() + 'api/user-permissions/' + id + '/', { headers: AuthHeader() });
  return response.data;
}

export async function DeleteHub(id: string) {
  const response = await axios.delete(baseUrl() + 'api/hub/' + id + '/', { headers: AuthHeader() });
  return response.data;
}

// ========================
// PATCH Requests
// ========================

export async function PatchConfigFiles(hub_id: string, files: Record<string, File>) {
  const formData = new FormData();
  Object.entries(files).forEach(([key, value]) => formData.append(key, value));
  return await axios.patch(baseUrl() + 'api/commands/' + hub_id + '/config/', formData, {
    headers: {
      ...AuthHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });
}

// ========================
// Other Functions
// ========================

export function TaskLogEventSource(id: string): EventSource {
  return new EventSource(`${baseUrl()}api/commands-stream/${id}/`);
}
