import { Badge, Card, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { TaskLogEventSource } from '../../apiService';
import { TaskDTO } from '../../dto';
import { errorColor, infoColor, iquColor } from '../../styles';
import { LoadingSpinner } from '../loading/LoadingSpinner';

interface TaskLogInterface {
  id: string | undefined;
}

export function TaskLog({ id }: TaskLogInterface) {
  const [tasks, setTasks] = useState<TaskDTO[]>([]);

  useEffect(() => {
    if (!id) return;

    const eventSource = TaskLogEventSource(id);

    eventSource.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);

      setTasks((prevTasks) => {
        const updatedTasks = [...prevTasks.filter((task) => task.id !== parsedData.id), parsedData];
        return updatedTasks.sort((a, b) => b.id - a.id);
      });
    };

    return () => {
      eventSource.close();
    };
  }, [id]);

  if (!id) {
    return <LoadingSpinner />;
  }

  function statusCodeMap(statusCode: number) {
    switch (statusCode) {
      case 1:
        return { status: 'success', color: iquColor };
      case 2:
        return { status: 'error', color: errorColor };
      default:
        return { status: 'pending', color: infoColor };
    }
  }

  return (
    <>
      <Heading as={'h1'}>{t('task_log_uppercase')}</Heading>
      <VStack spacing="10px" w={'100%'} h={'35vh'} paddingTop={'20px'} paddingInline={'20px'} overflowY={'auto'}>
        {Object.entries(tasks).map(([id, entry]) => (
          <Card w={'100%'} h={'50px'} key={id} justifyContent={'center'} flexShrink={0}>
            <SimpleGrid columns={4} spacing={1} justifyItems={'center'} alignItems={'center'}>
              <Badge fontSize="0.8em">{entry.id}</Badge>
              <Text ml={'1'} gridColumn="span 2">
                {entry.command}
              </Text>
              <Badge bg={statusCodeMap(entry.status).color} fontSize="0.8em" color={'white'}>
                {t(statusCodeMap(entry.status).status)}
              </Badge>
            </SimpleGrid>
          </Card>
        ))}
      </VStack>
    </>
  );
}
