import { Button } from '@chakra-ui/react';
import { t } from 'i18next';
import { ReactNode } from 'react';
import { SetDockerImage } from './SetDockerImage';
import { SubmitFiles } from './SubmitFiles';
import { UploadFile } from './UploadConfig';

interface CommandProps {
  hubId: string;
  openSubmitCommandAlert: (title: string, onSubmitCallback: () => void) => void;
  sendCommand: (command: string, commandName: string, data?: unknown) => void;
  commandName: string;
}

interface CommandConfig {
  component: (props: CommandProps) => ReactNode;
}

const createButtonCommand = () => {
  const CommandButton = ({ openSubmitCommandAlert, sendCommand, commandName }: CommandProps) => (
    <Button
      onClick={() => {
        openSubmitCommandAlert(t(commandName), () => sendCommand(commandName, commandName));
      }}
    >
      {t(commandName)}
    </Button>
  );
  return CommandButton;
};

export const commandConfig: Record<string, CommandConfig> = {
  upload_docker_compose: {
    component: ({ hubId, openSubmitCommandAlert, sendCommand, commandName }: CommandProps) => (
      <UploadFile
        {...{ hubId, openSubmitCommandAlert, sendCommand }}
        fileName="docker/docker-compose.yaml"
        commandName={commandName}
      />
    ),
  },
  download_docker_compose: {
    component: ({ hubId, openSubmitCommandAlert, sendCommand, commandName }: CommandProps) => (
      <SubmitFiles
        {...{ hubId, openSubmitCommandAlert, sendCommand }}
        forced_file_name="docker/docker-compose.yaml"
        commandName={commandName}
      />
    ),
  },
  new_config: {
    component: ({ hubId, openSubmitCommandAlert, sendCommand, commandName }: CommandProps) => (
      <SubmitFiles
        {...{ hubId, openSubmitCommandAlert, sendCommand }}
        directoryHelp="configs/"
        commandName={commandName}
      />
    ),
  },
  upload_config: {
    component: ({ hubId, openSubmitCommandAlert, sendCommand, commandName }: CommandProps) => (
      <UploadFile
        {...{ hubId, openSubmitCommandAlert, sendCommand }}
        fileName="configs.zip"
        commandName={commandName}
      />
    ),
  },
  set_docker_image: {
    component: ({ hubId, openSubmitCommandAlert, sendCommand, commandName }: CommandProps) => (
      <SetDockerImage {...{ hubId, openSubmitCommandAlert, sendCommand }} commandName={commandName} />
    ),
  },
  restart_docker_compose: {
    component: createButtonCommand(),
  },
  docker_compose_pull: {
    component: createButtonCommand(),
  },
  reboot: {
    component: createButtonCommand(),
  },
  start: {
    component: createButtonCommand(),
  },
  stop: {
    component: createButtonCommand(),
  },
  destruct: {
    component: createButtonCommand(),
  },
  git_pull: {
    component: createButtonCommand(),
  },
};
