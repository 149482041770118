import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { GetDockerImages, PostDockerImages } from '../../apiService';
import { iquColor, whiteColor } from '../../styles';

interface SetDockerImageInterface {
  hubId: string;
  openSubmitCommandAlert: (title: string, onSubmitCallback: () => void) => void;
  sendCommand: (command: string, commandName: string, data?: unknown) => void;
  commandName: string;
}
export function SetDockerImage({
  hubId: customer_id,
  openSubmitCommandAlert,
  commandName: command_name,
}: SetDockerImageInterface) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dockerImages, setDockerImages] = useState<Record<string, string>>();

  useEffect(() => {
    if (isOpen) return;

    const fetchDockerImages = () => {
      GetDockerImages(customer_id)
        .then((images) => {
          setDockerImages(images);
        })
        .catch(() => {
          setDockerImages({});
        });
    };

    fetchDockerImages();
    const intervalId = setInterval(fetchDockerImages, 5000);

    return () => clearInterval(intervalId);
  }, [customer_id, isOpen]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
        }}
      >
        {t(command_name)}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{t('set_docker_image')}</ModalHeader>
          <ModalBody pb={6}>
            {!dockerImages && <Spinner />}
            {dockerImages && Object.keys(dockerImages).length === 0 && <Text>{t('no_docker_images_found')}</Text>}
            <VStack align={'end'}>
              {dockerImages &&
                Object.entries(dockerImages).map(([key, image]) => (
                  <VStack key={key} w={'100%'} mb={4} align={'start'}>
                    <Text>{key}:</Text>
                    <HStack justify={'end'} w={'100%'}>
                      <Input
                        w={'100%'}
                        onChange={(e) => {
                          setDockerImages((prevDockerImages) => ({
                            ...prevDockerImages,
                            [key]: e.target.value,
                          }));
                        }}
                        value={image}
                      />
                    </HStack>
                  </VStack>
                ))}

              <Button
                bg={iquColor}
                color={whiteColor}
                onClick={() => {
                  openSubmitCommandAlert(t(command_name), () =>
                    PostDockerImages(customer_id, dockerImages).then(onClose),
                  );
                }}
              >
                Save
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
