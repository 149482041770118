import {
  Button,
  Card,
  Divider,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { FaDownload, FaUpload } from 'react-icons/fa6';
import { IoReload } from 'react-icons/io5';
import { GetConfigFile, GetConfigFileStatus } from '../../apiService';
import { configStatusDTO } from '../../dto';

interface UploadConfigInterface {
  hubId: string;
  openSubmitCommandAlert: (title: string, onSubmitCallback: () => void) => void;
  sendCommand: (command: string, commandName: string, data?: unknown) => void;
  fileName: string;
  commandName: string;
}
export function UploadFile({
  hubId: hub_id,
  openSubmitCommandAlert,
  sendCommand,
  fileName: file_name,
  commandName: command_name,
}: UploadConfigInterface) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [configStatus, setConfigStatus] = useState<configStatusDTO[]>();
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const refresh = useCallback(() => {
    setRefreshing(true);
    GetConfigFileStatus(hub_id, file_name)
      .then((r) => {
        setConfigStatus(r);
        setRefreshing(false);
      })
      .catch(() => {
        setConfigStatus(undefined);
        setRefreshing(false);
      });
  }, [hub_id]);

  useEffect(() => {
    if (isOpen) {
      const intervalId = setInterval(refresh, 5000);
      return () => clearInterval(intervalId);
    }
  }, [isOpen, refresh]);

  async function downloadConfigFile(file_path: string): Promise<void> {
    try {
      GetConfigFile(hub_id, file_path).then((r) => {
        const url = window.URL.createObjectURL(r.data);
        const link = Object.assign(document.createElement('a'), { href: url, download: file_name });
        link.click();
      });
    } catch (error) {
      console.error(t('error_downloading_file'), ':', error);
    }
  }

  return (
    <>
      <Button
        value={'upload-config'}
        onClick={() => {
          onOpen();
        }}
      >
        {t(command_name)}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{t('config')}</ModalHeader>
          <ModalBody>
            <Text fontSize={'xl'} mb={2} w={'100%'}>
              {t(command_name)}:
            </Text>
            <Flex justify={'end'} w={'100%'}>
              <IconButton
                aria-label={'Upload'}
                icon={<FaUpload size={20} />}
                w={'100%'}
                onClick={() => {
                  openSubmitCommandAlert(t(command_name), () => sendCommand(command_name, t(command_name)));
                }}
              />
            </Flex>
            <Divider mt={12} mb={8} />
            <Flex w={'100%'}>
              <Text fontSize={'xl'} mb={2} w={'100%'}>
                {t('download')}:
              </Text>
              <Flex>
                <IconButton
                  bg={'none'}
                  aria-label={'refresh'}
                  icon={refreshing ? <Spinner /> : <IoReload size={20} />}
                  onClick={() => {
                    refresh();
                  }}
                />
              </Flex>
            </Flex>
            {configStatus ? (
              configStatus
                .sort((a, b) => new Date(b.uploaded_on).getTime() - new Date(a.uploaded_on).getTime())
                .map((configStatus) => (
                  <Card p={2} mb={6} key={configStatus.uploaded_on}>
                    <Text fontSize={'lg'}>{file_name}</Text>
                    <Flex align={'center'}>
                      <Text w={'70%'}>
                        {t('uploaded_on')}
                        {': '}
                        {new Date(configStatus.uploaded_on).toLocaleString(undefined, {
                          hour12: false,
                        })}
                      </Text>
                      <Flex justify={'end'} w={'30%'} align={'center'}>
                        <IconButton
                          aria-label={'Download'}
                          icon={<FaDownload size={20} />}
                          onClick={() => {
                            downloadConfigFile(configStatus.file_path);
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Card>
                ))
            ) : (
              <Text>{t('no_file_found')}</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
