import { Box, HStack, IconButton, Tooltip } from '@chakra-ui/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { PiWarning } from 'react-icons/pi';
import { GetHubFlagsAPI } from '../../../apiService';
import { FlagDto, HubDTO } from '../../../dto';
import { errorColor, iquColor } from '../../../styles';
import { IsOnline } from '../../TimeUtils/TimeUtils';

interface StatusHubIconInterface {
  hub: HubDTO;
}

export function StatusHubIcon({ hub }: StatusHubIconInterface) {
  const lastConnection = new Date(hub.last_contact);
  const today = new Date();
  const color = IsOnline(hub.last_contact) ? iquColor : errorColor;
  const timeSinceContactSeconds = (today.getTime() - lastConnection.getTime()) / 1000;

  const [flags, setFlags] = useState<FlagDto[]>();

  useEffect(() => {
    GetHubFlagsAPI(hub.hub_id).then((response) => {
      const active_flags = response.filter((flag) => flag.is_active);
      setFlags(active_flags);
    });
  }, [hub.hub_id]);

  function ConvertSecToDay(n: number) {
    const day = parseInt(String(n / (24 * 3600)));

    n = n % (24 * 3600);
    const hour = parseInt(String(n / 3600));

    n %= 3600;
    const minutes = n / 60;

    n %= 60;
    const seconds = n;

    return (
      day +
      ' ' +
      t('days_lowercase') +
      ' ' +
      hour +
      ' ' +
      t('hours_lowercase') +
      ' ' +
      minutes.toFixed() +
      ' ' +
      t('minutes') +
      ' ' +
      seconds.toFixed() +
      ' ' +
      t('seconds_lowercase') +
      ' '
    );
  }

  return (
    <Box top={0} right={0} position={'absolute'}>
      <HStack spacing={2}>
        {flags && flags.length > 0 && (
          <Tooltip label={t('number_of_flags') + ': ' + flags.length}>
            <IconButton aria-label={t('connection')} bg={errorColor} color={'white'} icon={<PiWarning />} />
          </Tooltip>
        )}
        <Tooltip label={t('last_contact') + ': ' + ConvertSecToDay(timeSinceContactSeconds) + t('ago_lowercase')}>
          <IconButton aria-label={t('connection')} bg={color} color={'white'} icon={<AiOutlineInfoCircle />} />
        </Tooltip>
      </HStack>
    </Box>
  );
}
