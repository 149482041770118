import { Box, Image, Input, Skeleton, Text, useColorMode } from '@chakra-ui/react';
import { Autocomplete, GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import IQULogo from '../../../assets/images/iQU_transparent.png';
import { LatLngDTO } from '../../../dto';
import { grayishBlue, whiteColor } from '../../../styles';
import mapStylesDark from '../../map/jsonStylingDark.json';
import mapStylesLight from '../../map/jsonStylingLight.json';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  margin: '0 auto',
  borderRadius: '14px',
};

const defaultCenter = {
  lat: 60.2553909778811,
  lng: 11.682766697499536,
};

interface PositionSelectorProps {
  location: LatLngDTO;
  setLocation: (location: LatLngDTO) => void;
}

export function MapLocationSelector({ location, setLocation }: PositionSelectorProps) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries: ['places'],
  });
  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState(defaultCenter);
  const isDarkMode = useColorMode().colorMode === 'dark';
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (location) {
      setZoom(10);
      setCenter(location);
    }
  }, [location]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      setLocation(event.latLng.toJSON());
    }
  };

  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry?.location) {
        const newLocation = place.geometry.location.toJSON();
        setLocation(newLocation);
        setCenter(newLocation);
        setZoom(14);
      }
    }
  };

  if (loadError) {
    return <Text>Error loading maps</Text>;
  }

  return (
    <>
      <Skeleton isLoaded={isLoaded} height="100%" width="100%">
        <Box position="relative" w={'100%'} h={'500px'}>
          {isLoaded && (
            <>
              <Autocomplete
                onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                onPlaceChanged={handlePlaceSelect}
              >
                <Input
                  ref={inputRef}
                  placeholder={t('search_location')}
                  position="absolute"
                  top="10px"
                  left="50%"
                  transform="translateX(-50%)"
                  zIndex={2}
                  width="80%"
                  backgroundColor="white"
                  borderRadius="8px"
                  p={2}
                  boxShadow="md"
                />
              </Autocomplete>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={zoom}
                options={{
                  disableDefaultUI: true,
                  styles: isDarkMode ? mapStylesDark : mapStylesLight,
                  zoomControl: true,
                  mapTypeControl: false,
                  streetViewControl: false,
                  minZoom: 2.2,
                }}
                onClick={handleMapClick}
              >
                <Marker position={location} />
                <Box
                  h="24px"
                  bg={isDarkMode ? grayishBlue : whiteColor}
                  position="absolute"
                  bottom="0"
                  left="0"
                  right="0"
                  zIndex="2"
                >
                  <Image src={IQULogo} objectFit="contain" width={{ base: '10%', md: '5%' }} height="100%" />
                </Box>
              </GoogleMap>
            </>
          )}
        </Box>
      </Skeleton>
    </>
  );
}
